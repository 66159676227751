a.btn.btn-primary, input.btn.btn-primary, button.btn.btn-primary,
.product-block .product-quantity .num .change-num.change-num-plus,
.page.page-inner .page-side .lang-switcher a,
.page.page-inner .page-container .page-heading .heading-l.heading-yellow{
  background-color:#817683 !important;
}
.page.page-inner .page-container .page-heading .heading-r a.heading-cart.nonempty
{
  background:none !important;
}

.card-track{
  box-shadow: 0px 10px 14px 2px #D3D1D4;
}
.search{
  border: solid 1px #B1ADB4;
  /* box-shadow: 0px 11px 12px #D3D1D4; */
}
.main-track-circle{
  box-shadow: 0px 0px 5px #D3D1D4;
}
.inner-track-circle{
  box-shadow: 0px 0px 5px #D3D1D4;
}
.contact-border-green{
  border-bottom: 1px solid #817683;
}
a:hover{
  color:#817683;
}
a.btn.btn-primary:hover, input.btn.btn-primary:hover, button.btn.btn-primary:hover,
.product-block .product-quantity .num .change-num.change-num-plus:hover,
.page.page-inner .page-container .page-btn.page-btn-checkout .total{
  background-color:#817683 !important;
}
.page.page-inner .page-container .page-heading .heading-r a.heading-cart.nonempty:hover
{
  background-color:none !important;
  background:none !important;

}

.sb-default .sb-wrapper.sb-copy{
  background: #817683 !important;
}


a.btn.btn-danger, input.btn.btn-danger, button.btn.btn-danger
{
  background-color:rgb(161, 40, 40);
  color:white;
}

a.btn.btn-danger:hover, input.btn.btn-danger:hover, button.btn.btn-danger:hover
{
  background-color:rgb(168, 33, 33);
  color:white;

}



.product-block .product-options .product-options-heading .clear a
{
  color:#817683
}

.product-block .product-options .product-options-heading .clear a:hover
{
  color:#817683
}

.checkbox:checked + label:after
{
  background-color:#817683
}
.checkbox + label:before {
  border-color:#817683
}

.bg-primary
{
  background-color:#817683 !important;
}
.color-primary{
  color:#817683 !important;
}

.page.page-inner .page-container .page-heading .heading-r a.heading-cart i.im.im-round {
  border-color:#817683
}

.page-side-inner a {
  background:none !important;
  color:white;
}
.card-counts{
  background: #817683;
  box-shadow: 0 0px 4px 0 #817683;
}

.radio:checked + label:after {
  background: #817683;
}
button.bg-primary:hover
{
  background: #817683 !important;
}

.intl-tel-input .selected-flag .iti-arrow {
  border-top: 9px solid #817683 !important;
}
.card-counts{
  background: #817683;
  box-shadow: 0 0px 4px 0 #817683;
}
.text-shadow{
  text-shadow: 2px 2px 3px black;
}


.lds-ellipsis div {
  background: #817683;
}

.cart-icon path{
  fill:white !important;
}

.top-bar .solid .cart-icon path{
  fill:black !important;
}
.comp-substitution .governate .radio + label:before{
  border:1.5px solid #79d600;

}
.color-blue{
  color: #1832d7;
}




.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:hover
{
  background-color:initial !important;
  border-color:#817683 !important;
  color:#817683 !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active
{
  background-color:#817683 !important;
  border-color:#817683 !important;
  color:white !important;
}


.color-grey {
  color:grey;
}
.text-black {
  color:black;
}
h3{
  font-size:1.2rem;
}
.bc-primary{
  border-color:black !important;
}
.bc-grey{
  border-color:grey !important;
}
.bc-lighgrey{
  border-color:lightgrey
}
.selectable{
  color:grey;
}

.selectable.active{
  background:lightgrey;
  color:black;
}


.rounded {
  border-radius: 0.5rem !important;
}
.lh-1{
  line-height: 1.3;
}
.border-black{
  border-color: black !important;
}


.border-black-ex{
  box-shadow: 0 0 1px 1px black;
}



.text-grey{
  color:#817683;
}
.text-white{
  color:white;
}

