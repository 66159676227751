// Color of overlay background
$color-overlay: rgba(0, 0, 0, .5) !default;

// Position of dialog
$dialog-position-top: 1.75rem !default;

// Transition time
// !! The same as the hideDelay variable defined in ngx-smart-modal.component.ts
$transition-duration: 500ms !default;

// Transition effect
// linear | ease | ease-in | ease-out | ease-in-out
$transition-timing-function: ease-in-out !default;

// Body if modal is opened
body.dialog-open {
  overflow: hidden;
}

// Close button in modal
.nsm-dialog-btn-close {
  border: 0;
  background: none;
  color: #2d2d2d;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color $transition-duration;
  background-color: transparent;
  z-index: 999;

  &.nsm-overlay-open {
    background-color: $color-overlay;
  }

  &.transparent {
    background-color: transparent;
  }
}

// Dialog modal
.nsm-dialog {
  position: relative;
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  pointer-events: none;

  // When dialog is closing
  &.nsm-dialog-close {
    opacity: 0;
  }

  &.nsm-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
  }
}

.nsm-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 2px;
  padding: 1rem;
  margin: $dialog-position-top;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, .2), 0 13px 19px 2px rgba(0, 0, 0, .14), 0 5px 24px 4px rgba(0, 0, 0, .12);
  outline: 0;

  // For performance purpose
  transform: translate3d(0, 0, 0);
}

.nsm-body {
  position: relative;
  flex: 1 1 auto;
}

/* *************************
* Animations
* *************************/

.nsm-dialog[class*=nsm-dialog-animation-] {
  transition: transform $transition-duration $transition-timing-function, opacity $transition-duration;
}

// Left to right (ltr)
.nsm-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(-50%, 0, 0);
  }
}

// Right to left (ltr)
.nsm-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(50%, 0, 0);
  }
}

// Top to bottom (ttb)
.nsm-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(0, -50%, 0);
  }
}

// Bottom to top (btt)
.nsm-dialog-animation-btt {
  transform: translate3d(0, 50%, 0);

  &.nsm-dialog-open {
    transform: translate3d(0, 0, 0);
  }

  &.nsm-dialog-close {
    transform: translate3d(0, 50%, 0);
  }
}
